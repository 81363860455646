import portfolio from '../images/projects/portfolio.gif'
import dev_gif_1 from '../images/projects/dev_gif_1.gif'
import dev_gif_2 from '../images/projects/dev_gif_2.gif'
import dev_gif_3 from '../images/projects/dev_gif_3.gif'
import dev_gif_4 from '../images/projects/dev_gif_4.gif'
import dev_gif_5 from '../images/projects/dev_gif_5.gif'
import dev_gif_6 from '../images/projects/dev_gif_6.gif'

export const projs = [
    {
        image: portfolio,
        link: 'portfolio',
        desc: 'portfolio',
        summary: 'portfolio',
        tech: 'portfolio',
    },
    {
        image: dev_gif_1,
        link: 'dev_gif_1',
        desc: 'dev_gif_1',
        summary: 'dev_gif_1',
        tech: 'dev_gif_1',
    },
    {
        image: dev_gif_2,
        link: 'dev_gif_2',
        desc: 'dev_gif_2',
        summary: 'dev_gif_2',
        tech: 'dev_gif_2',
    },
    {
        image: dev_gif_3,
        link: 'dev_gif_3',
        desc: 'dev_gif_3',
        summary: 'dev_gif_3',
        tech: 'dev_gif_3',
    },
    {
        image: dev_gif_4,
        link: 'dev_gif_4',
        desc: 'dev_gif_4',
        summary: 'dev_gif_4',
        tech: 'dev_gif_4',
    },
    {
        image: dev_gif_5,
        link: 'dev_gif_5',
        desc: 'dev_gif_5',
        summary: 'dev_gif_5',
        tech: 'dev_gif_5',
    },
    {
        image: dev_gif_6,
        link: 'dev_gif_6',
        desc: 'dev_gif_6',
        summary: 'dev_gif_6',
        tech: 'dev_gif_6',
    },
]

export const techs = [
    {
        name: 'Python',
        iconName: 'python',
    },
    {
        name: 'Django',
        iconName: 'django',
    },
    // {
    //     name: 'Flask',
    //     iconName: 'flask',
    // },
    {
        name: 'Java',
        iconName: 'java',
    },
    {
        name: 'Javascript',
        iconName: 'javascript',
    },
    {
        name: 'React',
        iconName: 'react',
    },
    {
        name: 'PostgreSQL',
        iconName: 'postgresql',
    },
    {
        name: 'MongoDB',
        iconName: 'mongodb',
    },
    {
        name: 'Docker',
        iconName: 'docker',
    },
]
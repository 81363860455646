import React from 'react'
import './aboutMe.css'
import my_image from '../../images/my_image.jpg'

function AboutMe() {
  return (
    <div className="about__container" id="about-me">
        <div>
          <img src={my_image} alt="icon" />
        </div>
        <div className="about__text">
            <hi>ABOUT ME</hi>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Commodi voluptates consequatur quo velit iure earum. Eveniet et tenetur, cumque est quas illum similique sint excepturi veniam esse, minus repellendus labore.</p>
        </div>
    </div>
  )
}

export default AboutMe
import React from 'react'
import './contactMe.css'
import { navigationLinks } from '../../helpers/navigationLinks'

function createLinks() {
    return navigationLinks.map((e, idx) => (
        <p key={idx}><a href={e.ref}>{e.name}</a></p>
    ))
}

function ContactMe() {
  return (
    <div className='contactMe__container' id='contact'>
        <div className="contactMe__data__links">
            <div>
                <p>UK, London</p>
                <p>Miniiar Rakhimkulov</p>
                <p>miniyar23@gmail.com</p>
            </div>
            <div>
                <p>LinkedIn</p>
                <p>GitHub</p>
                <p>Medium</p>
            </div>
            <div style={{textAlign: 'right'}}>
                {createLinks()}
            </div>
        </div>
        <div style={{textAlign: 'center'}}>
            <p>Copyright&copy; {new Date().getFullYear()} All rights reserved</p>
        </div>
    </div>
  )
}

export default ContactMe
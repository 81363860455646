import React, { useState } from "react";
import { projs } from "../../helpers/projs";
import "./projects.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function Projects() {
  const [modalShow, setModalShow] = useState(false);
  const [tempData, setTempData] = useState({});

  function createModal(data) {
    return (
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        arial-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {data.desc}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>{data.summary}</p>
            <Image src={data.image} style={{width: '200px'}}></Image>
        </Modal.Body>
        <a id='portfolio__modal__link' href={data.link} target="_blank" rel="noreferrer">Go to site</a>
        <Modal.Footer>
            <div>Technologies used: </div>
            <p style={{fontSize: '0.7rem', marginRight: 'auto'}}>{data.tech}</p>
            <Button onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const mapped_projects = projs.map((e, idx) => {
    return (
      <Card key={idx} id="projects__card__container">
        <Image
          className="projects__image"
          onClick={() => {
            setTempData({
              image: e.image,
              link: e.link,
              desc: e.desc,
              summary: e.summary,
              tech: e.tech,
            });
            setModalShow(true);
          }}
          src={e.image}
        />
        <div className="projects__click__info">&#x1F50D;&#xFE0E;</div>
        {createModal(tempData)}
      </Card>
    );
  });
  return (
    <div className="projects__main__container" id="projects">
      <h1 style={{ textAlign: "center" }}>PORTFOLIO</h1>
      <p style={{ textAlign: "center" }}>
        This is my github page:
        <a
          href="https://github.com/miniiarrr/"
          target="_blank"
          rel="noreferrer"
        >
          https://github.com/miniiarrr/
        </a>
      </p>
      <Container fluid="lg" style={{ padding: "2rem 0" }}>
        <Row>{mapped_projects}</Row>
      </Container>
    </div>
  );
}

export default Projects;
